<script>
import Face from '@/components/Face'
import { Button, message } from 'ant-design-vue'
import { postAction } from '@/command/netTool'
export default {
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      faceData: null,
    }
  },
  methods: {
    onBack() {
      this.$refs['face'].reset()
      this.$emit('back')
    },
    renderHeader() {
      return (
        <div class="face2-header">
          {this.form.mainName}
          <div class="face2-back" onClick={this.onBack}>
            <img src={require('./asset/back.png')} />
            <span>返回</span>
          </div>
        </div>
      )
    },
    onSuccess(data) {
      this.faceData = data
    },
    onSubmit() {
      postAction('/api/dataoperation/faceVerify/searchFace', {
        image: this.faceData,
        ...this.form,
        mainId: this.form.id,
      }).then((e) => {
        if (e.data.verifyStatus == '0') {
          message.error(`人脸识别成功率太低,请重新尝试`)
        } else {
          message.success('认证成功')
          this.$refs['face'].reset()
          this.$emit('success')
        }
      })
    },
    renderBody() {
      return (
        <div class="face2-body">
          <Face
            width={500}
            height={500}
            onSuccess={this.onSuccess}
            onFail={() => (this.faceData = null)}
            ref={'face'}
          />
          <Button
            disabled={!this.faceData}
            type="primary"
            style={{ marginTop: '20px', width: '300px', height: '40px' }}
            block={true}
            onClick={this.onSubmit}
          >
            点击提交
          </Button>
        </div>
      )
    },
  },
  render() {
    return (
      <div class="face2">
        {this.renderHeader()}
        {this.renderBody()}
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.face2 {
  margin: -24px;
  width: 110%;
  height: calc(100vh - 60px);
  background-image: url('./asset/bk.webp');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
}
.face2-header {
  width: 100%;
  height: 48px;
  background: rgba(24, 144, 255, 0.12);
  box-shadow: 0px 1px 4px rgba(0, 21, 41, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: white;
}
.face2-back {
  position: absolute;
  left: 20px;
  top: 0;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
  span {
    font-size: 14px;
    font-family: PingFangSC-Medium;
    color: #ffffff;
    white-space: nowrap;
  }
}
.face2-body {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-right: 80px;
}
.face2-view {
  width: 700px;
  height: 700px;
}
</style>
