<script>
import Face from '@/components/Face'
import { Select, Button, message } from 'ant-design-vue'
import faceTool from '../../command/faceTool'
import { postAction } from '@/command/netTool'
export default {
  data() {
    return {
      form: {
        idCard: null,
      },
      result: null,
      loading: false,
    }
  },
  watch: {
    // 'form.idCard'(newValue) {
    //   if ()
    // }
  },
  mounted() {
    console.log('生命周期')
    this.socket = faceTool.initSocket({
      success: (idCard) => {
        console.log('idCard', idCard)
        this.loading = false
        if (this.result) {
          postAction('/api/dataoperation/faceVerify/faceReg', {
            mainId: this.result.id,
            image: idCard.base64Data,
          }).then(() => this.$emit('submit', this.result))
        } else {
          this.form['idCard'] = idCard.certNo
        }
      },
      error: (msg) => {
        this.form['idCard'] = ''
        message.error(msg)
      },
    })
  },
  methods: {
    renderInput(placeholder, key) {
      return (
        <div class="input-group">
          <input type="text" placeholder={placeholder} v-model={this.form[key]} />
        </div>
      )
    },
    getMainBodyList(name) {
      postAction('/api/dataoperation/faceVerify/searchMainBody', {
        mainName: name,
      })
    },
    handleSearch(name) {
      this.getMainBodyList(name)
    },
    renderQuerySelectSearch(placeholder, key) {
      return (
        <div class="input-group">
          <Select
            show-search
            filter-option="false"
            style={{ width: '100%' }}
            onSearch={this.handleSearch}
            change="handleChange"
            show-search
            placeholder={placeholder}
          >
            <Select.Option value="1">查询1</Select.Option>
            <Select.Option value="2">查询2</Select.Option>
          </Select>
        </div>
      )
    },
    renderQuerySelect(placeholder, key, typeData = []) {
      return (
        <div class="input-group">
          <Select style={{ width: '100%' }} show-search placeholder={placeholder} v-model={this.form[key]}>
            {typeData.map((e) => {
              return <Select.Option value={e.value}>{e.name}</Select.Option>
            })}
          </Select>
        </div>
      )
    },
    renderCreate() {
      return (
        <div class="face-create" onClick={() => window.open('/basicInformation/detail')}>
          去创建主体
        </div>
      )
    },
    onSumbitButton() {
      if (
        /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(
          this.form.idCard
        )
      ) {
        if (this.form.idType != undefined) {
          postAction(`/api/dataoperation/faceVerify/searchMainBody?idCard=${this.form.idCard}`)
            .then((e) => {
              if (e.data.verifyStatus == '1') {
                message.error('已经认证过无需重复认证')
              } else {
                this.result = { ...e.data, idType: this.form.idType }
                // regStatus 0:未注册 1:已注册
                if (e.data.regStatus == '0') {
                  if (this.socket.readyState == 1) {
                    this.loading = true
                    this.socket.send('EST_Reader_ReadIDCard#')
                  } else {
                    message.error('请确保连接身份证识别设备')
                  }
                } else {
                  this.$emit('submit', this.result)
                }
              }
            })
            .catch((e) => message.error(e.msg))
        } else {
          message.error('请选择身份')
        }
      } else {
        message.error('身份证有误')
      }
    },
    renderButton() {
      return (
        <Button
          loading={this.loading}
          block={true}
          type="primary"
          style={{ color: 'white', height: '40px' }}
          onClick={this.onSumbitButton}
        >
          开始识别
        </Button>
      )
    },
  },
  render() {
    const selectType = [
      {
        name: '经营者',
        value: '0',
      },
      {
        name: '员工',
        value: '1',
      },
    ]
    return (
      <div class="face-main">
        <div class="face-body">
          <img class="face-logo" src={require('./asset/logo.webp')} />
          {/*this.renderQuerySelectSearch('请选择主体名称', 'name')*/}
          {/* 身份证号*/}
          {this.renderInput('请输入身份号', 'idCard')}
          {/* 身份证姓名 */}
          {/*this.renderInput('请输入身份证姓名', 'idName')*/}
          {/* 请选择身份 */}
          {this.renderQuerySelect('请选择身份', 'idType', selectType)}
          {/* 去创建主体 */}
          {this.renderCreate()}
          {/* 提交按钮 */}
          {this.renderButton()}
        </div>
      </div>
    )
  },
}
</script>
<style lang="less"></style>
<style lang="less" scoped>
.face-main {
  display: flex;
  height: calc(100vh - 60px);
  width: 110%;
  margin: -24px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-image: url('./asset/bk.webp');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /deep/ .ant-select {
    width: 100%;
    color: #06b0fe;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #2680eb !important;
  }
  /deep/ .ant-select-selection--single {
    background: rgba(0, 0, 0, 0.08) !important;
    width: 100%;
    height: 100%;
  }
  /deep/ .ant-select-selection__rendered {
    line-height: 36px;
  }
  /deep/ .ant-select-arrow {
    color: #06b0fe;
    font-size: 14px;
    font-weight: 600;
  }
  .ant-select-dropdown {
    /* color: rgba(255, 255, 255, .7); */ /* background-color: #040324;  background: #040324; */ /* font-size: 22px; */ /* border: 1px solid #06B0FE; */
  }
  /deep/.ant-select-dropdown-menu {
    background-color: #040324;
    background: #040324;
    border: 1px solid #06b0fe;
    font-size: 16px;
  }
  /deep/.ant-select-dropdown-menu-item {
    padding: 0px 12px !important;
    height: 60px !important;
    line-height: 60px !important;
    font-size: 16px !important;
    color: rgba(255, 255, 255, 0.7) !important;
  }
  /deep/.ant-select-dropdown-menu-item:hover {
    background: rgba(6, 176, 254, 0.2) !important;
    color: #06b0fe !important;
  }
  /deep/.ant-select-dropdown-menu-item-selected {
    background: rgba(6, 176, 254, 0.2) !important;
    color: #06b0fe !important;
  }
  /deep/.ant-select-dropdown-menu-item-active {
    background: rgba(6, 176, 254, 0.2) !important;
    color: #06b0fe !important;
  }
}
.face-body {
  width: 480px;
  height: 473px;
  min-width: 480px;
  min-height: 473px;
  background-image: url('./asset/body-bk.webp');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 50px;
  padding: 40px 56px 50px 56px;
  .face-logo {
    width: 84px;
    height: 84px;
  }
  & > *:not(.face-logo) {
    margin-top: 28px;
  }
}
.input-group {
  width: 100%;
  input {
    width: 100%;
    height: 40px !important;
    background: rgba(0, 0, 0, 0.08) !important;
    border: 1px solid #2680eb !important;
    padding: 0px;
    padding-left: 16px;
    font-size: 16px;
    font-family: PingFangSC-Regular;
    line-height: 24px;
    color: #b2b2b2 !important;
    outline: none !important;
    &::placeholder {
      color: #b2b2b2 !important;
    }
  }
}
.face-create {
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #52ccfd;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  cursor: pointer;
}
&/deep/.ant-select {
  background: rgba(0, 0, 0, 0.08);
  border: 1px solid #2680eb;
  & > div {
    background: rgba(0, 0, 0, 0.08);
  }
  & > * {
    border: none;
    outline: none;
  }
  svg {
    color: white;
  }
}
&/deep/.ant-select-dropdown-content {
  background: rgba(0, 0, 0, 0.08);
  border: 1px solid #2680eb;
}
</style>
