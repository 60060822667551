<script>
import View1 from './view1'
import View2 from './view2'

export default {
  name: 'face',
  data() {
    return {
      viewIndex: 0,
      form: null,
    }
  },
  methods: {
    onSubmit(data) {
      this.viewIndex = 1
      this.form = data
    },
    onFaceSuccess() {
      this.viewIndex = 0
    },
  },
  render() {
    return this.viewIndex == 0 ? (
      <View1 onSubmit={this.onSubmit} />
    ) : (
      <View2 form={this.form} onBack={() => (this.viewIndex = 0)} onSuccess={this.onFaceSuccess} />
    )
  },
}
</script>
