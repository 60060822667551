var socket
var time
var state = false
var reteyNum = 0
function initSocket({ success, error } = {}) {
  state = false
  reteyNum = 0
  var host = 'ws://127.0.0.1:33666' //客户端电脑本地IP，非服务器IP，无需修改
  if (socket == null) {
    socket = new WebSocket(host)
  } else {
    // resultMsg('已初始化.')
  }
  try {
    function retey() {
      time = setInterval(() => {
        if (!state) {
          socket.send('EST_Reader_ReadIDCard#')
        }
      })
    }
    socket.onopen = function () {
      retey()
      // resultMsg('初始化成功.') //可以注释
      // getVersion() //可以注释：控件版本号
    }
    socket.onclose = function () {
      // resultMsg('读卡服务已经断开.')
    }
    socket.onerror = function () {
      error('请确保身份证设备连接成功')
      // resultMsg('请检查控件是否正常安装，下载地址：...')
    }
    socket.onmessage = function (msg) {
      if (typeof msg.data == 'string') {
        var msgM = msg.data + ''
        var msgJson = JSON.parse(msgM)
        try {
          if (msgJson.fun == 'EST_Reader_ReadIDCard#' && msgJson.rCode == '0') {
            reteyNum = 0
            state = true
            success(msgJson)
          } else {
            state = false
            if (reteyNum == 0) {
              reteyNum += 1
              error('请放置身份证')
              retey()
            }
          }
        } catch (err) {
          state = false
          error('设备异常')
        }
        //resultMsg(msgM);
        // switch (msgJson.fun) {
        //   case 'EST_GetVersion#':
        //     resultMsg('版本号：' + msgJson.errMsg)
        //     break

        //   case 'EST_Reader_ReadIDCard#':
        //     if (msgJson.rCode == '0') {
        //       //普通身份证
        //       document.getElementById('text_name').value = msgJson.name //姓名
        //       document.getElementById('text_sex').value = msgJson.sex //性别
        //       document.getElementById('text_nation').value = msgJson.nation //民族
        //       document.getElementById('text_birthday').value = msgJson.birth //出生日期
        //       document.getElementById('text_address').value = msgJson.address //住址
        //       document.getElementById('text_idNum').value = msgJson.certNo //身份证号码
        //       document.getElementById('text_dept').value = msgJson.department //签发机关
        //       document.getElementById('text_effDate').value = msgJson.effectData //有效期开始
        //       document.getElementById('text_expDate').value = msgJson.expire //有效期结束

        //       //港澳台数据
        //       document.getElementById('text_GAT_tongxingzheng').value = msgJson.txzhm //通行证号码
        //       document.getElementById('text_GAT_qianfacishu').value = msgJson.txzqfcs //签发次数
        //       //外国人数据
        //       document.getElementById('text_WGR_yingwenxingming').value = msgJson.nameen //英文姓名
        //       document.getElementById('text_WGR_guoji').value = msgJson.nationcode //国籍代码
        //       document.getElementById('text_WGR_qianfadaima').value = msgJson.authorcode //签发机关代码
        //       document.getElementById('text_WGR_banben').value = msgJson.cardversion //证件版本

        //       //照片数据
        //       document.getElementById('PhotoID').src = 'data:image/jpeg;base64,' + msgJson.base64Data //身份证头像，base64格式数据，实际尺寸：102x126px
        //       document.getElementById('PhotoIDFront').src = 'data:image/jpeg;base64,' + msgJson.imageFront //身份证正面照片，实际尺寸：540x340px，如无需使用，建议注释掉
        //       document.getElementById('PhotoIDBack').src = 'data:image/jpeg;base64,' + msgJson.imageBack //身份证反面照片，实际尺寸：540x340px，如无需使用，建议注释掉
        //       resultMsg(msgJson.errMsg)
        //       posBeep()
        //     } else if (msgJson.rCode == '1') {
        //       resultMsg('已停止自动读卡')
        //     } else if (msgJson.rCode == '-2') {
        //       resultMsg('请放身份证')
        //     } else {
        //       resultMsg(msgJson.errMsg)
        //     }
        //     break

        //   default:
        //     break
        // }
      } else {
        // alert('连接异常,请检查是否成功安装控件.')
      }
    }
  } catch (ex) {
    // alert('连接异常,请检查是否成功安装控件.')
  }
  return socket
}

export default {
  initSocket,
}
